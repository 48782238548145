import toronto from '../assets/toronto.png';
import { useRef } from 'react';

export default function Home() {

    return (
        <section id="home">
            <div class="parentContainer">
                <div class="childContainer">

                    <h1>
                        <h1 class="title">RYAN CHAN</h1>
                        <a class="typewriteWhite">I'm a</a>
                        <a href="" class="typewrite" data-period="2000" data-type='[" Software Engineer.", " UI/UX Specialist.", " Front-End Developer." ]'>
                            <span class="wrap"></span>
                        </a>
                    </h1>
                    <div class="arrow-button">
                        <a href="#projects">
                            <div class="arrow-container">
                                <i class="arrow"></i>
                            </div>
                            <div class="arrow-bg"></div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};

window.onload = function () {
    var elements = document.getElementsByClassName('typewrite');
    for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
};
